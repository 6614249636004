import React from 'react';
import C4DVideoPlayerContainer from '@carbon/ibmdotcom-web-components/es/components-react/video-player/video-player-container';

interface VideoCompProps {
  mediaId: string;
}

const VideoComp: React.FC<VideoCompProps> = ({ mediaId }) => {
  return (
    <div>
      <C4DVideoPlayerContainer aspectRatio="16x9" videoId={mediaId} />
    </div>
  );
};

export default VideoComp;

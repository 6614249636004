import 'src/common/style/common.scss';
import { ReactElement } from 'react';

import { ThanksModal, thanksTable } from 'src/common/utils/ThanksModal';

export interface DemoThanksProps {
  openThanks: boolean;
  heading: string;
  body_text: string;
  url?: string;
  url_label?: string;
  close_modal: string;
  setShowThanksModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Thanks = ({
  openThanks,
  heading,
  body_text,
  url,
  url_label,
  close_modal,
  setShowThanksModal,
}: DemoThanksProps): ReactElement => {
  const titleThanks: thanksTable[] = [
    {
      group: 'title',
      value: String(heading),
    },
    {
      group: 'subTitle',
      value: String(body_text),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: 'widgetTitle1',
      value: '',
    },
  ];

  const contentThanks: thanksTable[] = [];

  const buttonThanks: thanksTable[] = [
    {
      group: 'button1',
      value: String(close_modal),
    },
  ];

  const demoContent = {
    demo: String(url),
    demoText: String(url_label),
  };

  return (
    <ThanksModal
      showThanksModal={openThanks}
      pageTitle="Update page title"
      object="Update this value"
      productTitle={'Update to use demo title'}
      pageUrl={''}
      location={'Figma Demo'}
      titleThanks={titleThanks}
      widgetTitleThanks={widgetTitleThanks}
      contentThanks={contentThanks}
      buttonThanks={buttonThanks}
      setShowThanksModal={setShowThanksModal}
      link={demoContent}
    ></ThanksModal>
  );
};

export default Thanks;

import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Button, Link } from 'carbon-components-react';
import { Maximize32, ArrowRight16 } from '@carbon/icons-react';
import { DSH_Figma_Utils } from 'src/common/figmaListener';
import countryList from '../data/currencies.json';
import { LangDefaults, useLang } from 'src/common/hooks/widgets/useLang';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import PublicImage from 'src/common/components/PublicImage';

import './FigmaDemo.scss';
import { FigmaConfig, segmentTracking } from '../types/types';
import DemoCtas from './DemoCtas';
import VideoComp from './VideoComp';
import DemoLanguageSelection from './DemoLanguageSelection';

//needed for languages - another change
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { resolve } from 'dns';
import { reject } from 'ramda';

declare global {
  interface Window {
    _analytics?: { segment_key: string };
  }
}

export interface FigmaDemoProps {
  langCode: string;
  test: boolean;
  countryToTest: string;
  demoConfig: string;
  localhostAddr: string;
  loadBma: string;
  showProductLink: string;
}
const FigmaDemo = ({
  langCode,
  test,
  countryToTest,
  demoConfig,
  localhostAddr,
  loadBma,
  showProductLink,
}: FigmaDemoProps) => {
  // Call the hook at the top level
  const detectedPhone =
    /iPhone|Android.*Mobile|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const [environment] = useEnv();
  const [usingFigmaListener, setUsingFigmaListener] = useState<boolean>(false);
  const [config, setConfig] = useState<FigmaConfig | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [styleOverride, setStyleOverride] = useState<object | null>(null);
  const [fullscreenLabel, setFullscreenLabel] =
    useState<string>('Launch fullscreen');
  const [smallScreenMessage, setSmallScreenMessage] = useState<string>(
    '* Recommended for larger screens *',
  );
  const [langLabel, setLangLabel] = useState<string>('Demo available in:');
  //lets set the language for use...
  const [currency, setCurrency] = useState('');
  const [country, setCountry] = useState('');
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [urxlang, setUrxLang] = useState(
    LangDefaults.localCountryLang as IBMLocale,
  );

  //testing out the switching of the demo
  const [currentDemoConfig, setCurrentDemoConfig] =
    useState<string>(demoConfig);
  const [currentDemoLang, setCurrentDemoLang] = useState<string>('');
  const [currentDemoLangMatrix, setCurrentDemoLangMatrix] =
    useState<string>('');

  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );

  // Create refs for the iframe and button
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const demoWrapRef = useRef<HTMLDivElement | null>(null);
  const videoWrapRef = useRef<HTMLDivElement | null>(null);
  const fullscreenBtnRef = useRef<HTMLButtonElement | null>(null);

  // for tracking the display toggle
  const [visibleDiv, setVisibleDiv] = useState('demo'); // default to "demo" or "video"

  const handleDemoToggle = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement> | null,
    action: string,
  ): void => {
    // Toggle logic
    if (event) {
      event.preventDefault(); // Prevents the default link behavior
    }
    setVisibleDiv(action);
  };

  // type SwitchDemoConfig2 = (config: string) => void;
  type SwitchDemoConfig2 = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;

  const switchDemoConfig2: SwitchDemoConfig2 = (event) => {
    event.preventDefault();
    const selectedValue = event.target.value;
    setVisibleDiv('demo');
    setLoading(true);
    setCurrentDemoConfig(selectedValue);
    // fetchFigmaConfig();
  };

  const loadFigmaTracking = function () {
    const finishedLoading = function () {
      const demoCover = document.querySelector(
        '.figma_demo_widget_wrap2 .figma_loading_cover',
      );
      setTimeout(function () {
        demoCover?.classList.add('fade_out');
      }, 250);
    };
    const clickCallbackFunc = (nodeID: string) => {
      if (config?.click_triggers) {
        console.log('Figma ID of clicked element is: ', nodeID);
        const clickTriggers = config?.click_triggers ?? [];
        const cTrigger = clickTriggers.find((trigger) =>
          trigger.ids.includes(nodeID),
        );

        if (cTrigger) {
          if (document.fullscreenElement && document.exitFullscreen) {
            document.exitFullscreen();
          }
          switch (cTrigger.type) {
            case 'click':
              const element = document.querySelector(
                cTrigger.action,
              ) as HTMLElement;
              element?.click();
              break;
            case 'url':
              window.open(cTrigger.action);
              break;
            case 'shadowroot':
              const selectors = cTrigger.action.split(':'); // Split the action on the colon
              const srElement = document.querySelector(selectors[0]); // Get the first part of the selector (the base element)
              if (selectors.length > 1 && srElement) {
                // If there's a second part, chain the querySelector calls
                const targElm = srElement?.shadowRoot?.querySelector(
                  selectors[1],
                ) as HTMLElement;
                targElm?.click(); // Typecast the element to HTMLElement to safely access .click()
              }
              break;
          }
        }
      }
    };

    DSH_Figma_Utils.init({
      figma_fileKey: config?.figma_file_key || '',
      figma_accessToken: config?.figma_access_token || '',
      product_Title: config?.product_title || '',
      clickCallback: clickCallbackFunc,
      metricsTracking: segmentTracking,
      loadedCallback: finishedLoading,
      page_Title: config?.demo_title || '',
    });
  };

  const renderLoadingMessage = (message: string) => (
    <div className="widget_loading_wrapper">
      <div className="widget_msg">
        <p>{message}</p>
      </div>
    </div>
  );

  const initializeAnalytics = (loadBma: string | undefined): void => {
    const base64Regex =
      /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (loadBma && loadBma?.trim() !== '') {
      const passedKey = loadBma + '=';
      if (base64Regex.test(passedKey)) {
        const segmentKeyObj = atob(passedKey).split(':');
        if (
          !window.bluemixAnalytics &&
          !window._analytics &&
          segmentKeyObj.length === 2 &&
          segmentKeyObj[0] &&
          segmentKeyObj[0] === 'dsh-tech'
        ) {
          window._analytics = {
            segment_key: segmentKeyObj[1],
          };
          // Create a new script element
          const script = document.createElement('script');
          script.src =
            'https://test.cloud.ibm.com/analytics/build/bluemix-analytics.min.js';
          script.async = true;
          script.onload = () =>
            console.log(
              'dev:',
              'bluemix analytics script loaded by demo widget',
            );
          script.onerror = () =>
            console.log(
              'dev:',
              'bluemix analytics script failed to be loaded by demo widget',
            );
          // Append the script to the document head
          document.head.appendChild(script);
        } else {
          console.log(
            'dev:',
            'bluemix analytics was already active on the page',
          );
        }
      }
    } else {
      console.log('dev:', 'not goting to load bluemix analytics');
    }
  };

  const fetchFigmaConfig = async () => {
    let url = null;
    if (
      window.location.hostname === 'localhost' &&
      localhostAddr &&
      localhostAddr.trim() !== ''
    ) {
      url =
        'http://localhost:' +
        localhostAddr.replaceAll('-', '/').replace(/\/$/, '') +
        '/' +
        currentDemoConfig +
        '.json';
    } else {
      url =
        'https://ace-project-bucket.s3.us-east.cloud-object-storage.appdomain.cloud/aem-demo-configs/' +
        currentDemoConfig +
        '.json';
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          'Config file could not be loaded.  Please make sure ' +
            currentDemoConfig +
            ' exists.',
        );
      }
      const data: FigmaConfig = await response.json();
      setConfig(data);

      if (data.style_override) {
        setStyleOverride(data.style_override);
      }

      if (data.fullscreen_label) {
        setFullscreenLabel(data.fullscreen_label);
      } else {
        setFullscreenLabel('Launch fullscreen');
      }
      if (data.small_screen_message) {
        setSmallScreenMessage(data.small_screen_message);
      } else {
        setSmallScreenMessage('* Recommended for larger screens *');
      }
      if (data.lang_label) {
        setLangLabel(data.lang_label);
      } else {
        setLangLabel('Demo available in:');
      }
      //determine if we have everything we need to use figma and its listener
      if (data.figma_access_token && data.figma_file_key && data.figma_url) {
        setUsingFigmaListener(true);
      }
      if (detectedPhone && data.video_id) {
        handleDemoToggle(null, 'video');
      }
      if (data.lang_code && data.language_selection) {
        setCurrentDemoLang(data.lang_code);
        setCurrentDemoLangMatrix(data.language_selection);
      } else {
        setCurrentDemoLang('');
        setCurrentDemoLangMatrix('');
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  /* Have urx form deault in us-en when on language us page */
  useEffect(() => {
    const fetchedUrxLang = localeCode; // us-en
    setUrxLang(userLocale as IBMLocale); // de-de
    if (fetchedUrxLang !== lang) {
      setUrxLang(fetchedUrxLang as IBMLocale); // default us-en
    }
    initializeAnalytics(loadBma);
  }, [userLocale, environment, loadBma]);

  useEffect(() => {
    const iframe = iframeRef.current;
    const fullscreenBtn = fullscreenBtnRef.current;

    if (iframe && fullscreenBtn) {
      const launchFullscreen = (element: HTMLElement) => {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
      };

      const handleFullscreenClick = (
        e: React.MouseEvent<HTMLButtonElement>,
      ) => {
        e.preventDefault();
        launchFullscreen(iframe);
      };

      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      };

      fullscreenBtn.addEventListener(
        'click',
        handleFullscreenClick as unknown as EventListener,
      );
      document.addEventListener('keydown', handleEscapeKey);

      // Clean up event listeners on unmount
      return () => {
        fullscreenBtn.removeEventListener(
          'click',
          handleFullscreenClick as unknown as EventListener,
        );
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
    fetchFigmaConfig();
  }, [loading]); //had loading in it

  if (loading) return renderLoadingMessage('No config file passed in...');
  if (error) return renderLoadingMessage(`Error: ${error}`);
  if (!config) return renderLoadingMessage('No configuration data found');

  return (
    <>
      {/* {currentDemoConfig} */}
      {usingFigmaListener && loadFigmaTracking()}
      <div
        className="figma_demo_widget_wrap2"
        style={styleOverride ? { ...styleOverride } : {}}
      >
        <div className="figma_demo_header">
          {config?.ctas && (
            <DemoCtas
              urxlang={userLocale}
              ctas={config.ctas}
              env={environment}
              demotitle={config.demo_title}
              producttitle={config.product_title}
              toggleButtons={
                config?.figma_url && config?.video_id
                  ? handleDemoToggle
                  : undefined // Optional, undefined if not provided
              }
              visibleDiv={visibleDiv}
              smallScreenMessage={smallScreenMessage}
            />
          )}
        </div>
        <div className="figma_demo_body">
          <div className="inner_wrap">
            {config?.figma_url ? (
              <div
                className="media_wrap"
                ref={demoWrapRef}
                style={
                  visibleDiv === 'video'
                    ? { display: 'none' }
                    : { display: 'block' }
                }
              >
                {config?.figma_url ? (
                  <iframe
                    ref={iframeRef}
                    tabIndex={-1}
                    className="figma_frame"
                    onLoad={() => {
                      window.parent.focus();
                    }}
                    title="Figma demo content"
                    id="figma_demo"
                    src={config.figma_url}
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div className="widget_msg">
                    <p>No figma url was provided in the config.</p>
                  </div>
                )}
                {config?.figma_url ? (
                  <Button
                    id="fullscreen-btn"
                    className="fullscreen_btn"
                    ref={fullscreenBtnRef}
                    renderIcon={Maximize32}
                    kind="primary"
                    hasIconOnly
                    isExpressive
                    iconDescription={fullscreenLabel}
                  ></Button>
                ) : null}
              </div>
            ) : null}

            {usingFigmaListener === true ? (
              <div className="figma_loading_cover">
                <PublicImage
                  imgPath={'images/rebus.png'}
                  widgetId="figma-demo-widget"
                  alt="animation-image"
                />
              </div>
            ) : null}

            {config?.video_id && visibleDiv === 'video' ? (
              <div className="media_wrap" ref={videoWrapRef}>
                <VideoComp mediaId={config.video_id} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="figma_demo_footer">
          <div className="productLink">
            {showProductLink &&
            showProductLink === 'true' &&
            config.product_url != null ? (
              <Link href={config.product_url} renderIcon={ArrowRight16}>
                {config.product_title}
              </Link>
            ) : null}
          </div>
          {currentDemoLang.trim() !== '' &&
          currentDemoLangMatrix.trim() !== '' ? (
            <DemoLanguageSelection
              localhostAddr={localhostAddr}
              selectedDemoConfig={currentDemoConfig}
              langMatrix={currentDemoLangMatrix}
              langLabel={langLabel}
              func={switchDemoConfig2}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FigmaDemo;

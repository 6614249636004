export interface CtasButton {
  thanks?: Thanks;
  title: string;
  urx_lang?: string;
  urx?: string;
  url?: string;
}

interface Thanks {
  heading: string;
  body: string;
  url?: string;
  url_label?: string;
  close_modal: string;
}

interface Cta {
  title: string;
  urx_lang?: string;
  urx?: string;
  url?: string;
}

interface ClickTrigger {
  ids: string[];
  action: string;
  type: string;
}

export interface FigmaConfig {
  lang_label: string;
  language_selection?: string;
  lang_code?: string;
  small_screen_message: string;
  fullscreen_label: string;
  style_override?: object;
  figma_access_token?: string;
  figma_file_key: string;
  product_title: string;
  product_url: string;
  demo_title: string;
  figma_url: string;
  video_id?: string;
  click_triggers?: ClickTrigger[];
  ctas: Cta[];
}

interface segmentProperties {
  productTitle?: string;
  pageTitle: string;
  object: string;
  pageUrl?: string;
  successFlag?: boolean;
  resultValue: string;
  CTA: string;
  location: string;
  action: string;
  field: string;
}

export interface FigmaDemoProps {
  localhostAddr: string;
  loadBma: string;
  demoConfig: string;
  showProductLink: string;
}

/** how we will communicate with bluemix analytics **/
export const segmentTracking = (
  eventName: string,
  {
    productTitle,
    pageTitle,
    object,
    pageUrl,
    successFlag,
    resultValue,
    CTA,
    location,
    action,
    field,
  }: segmentProperties,
) => {
  if (window?.bluemixAnalytics && typeof window.bluemixAnalytics === 'object') {
    window.bluemixAnalytics.trackEvent(eventName, {
      productTitle,
      pageTitle,
      pageUrl,
      object,
      successFlag,
      resultValue,
      CTA,
      location,
      action,
      field,
    });
  } else {
    console.log(`Bluemix analytics not available for event: ${eventName}`);
  }
};
